.Username {
  .status {
    font-size: 0.5rem;

    &.online {
      color: green;
    }

    &.away {
      color: orange;
    }

    &.offline {
      color: grey;
    }
  }

  .fa-user-shield {
    color: #096484;
  }

  .fa-gem {
    color: #7ed0e2;
  }

  .btn-link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .max-width {
    max-width: 6rem;
    max-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
