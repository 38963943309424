.RoomCompareResultsContainer {
  min-width: var(--bs-offcanvas-width);
  width: auto !important;
  max-width: 75% !important;
}

.RoomCompareResults {
  &.table {
    text-align: center;
    margin: auto auto 4rem;
  }

  thead th.vertical,
  tfoot th.vertical {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    vertical-align: middle;
  }

  tr th:first-child,
  tr td:first-child,
  tr td.empty, {
    background-color: var(--bs-table-striped-bg);;
  }
}
