@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2rem);
  }
  50% {
    transform: translateX(-2rem);
  }
  75% {
    transform: translateX(2rem);
  }
  100% {
    transform: translateX(0);
  }
}

.GameInput {
  &.shaking {
    animation: horizontal-shaking 0.35s infinite;
  }

  input {
    padding: 0 1rem;
  }
}
