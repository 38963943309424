.GameProgress {
  width: 75%;
  margin: auto;
  text-align: center;

  @media (width <= 400px) {
    & {
      width: 100%;
    }
  }

  & > * {
    margin-top: .75rem;
  }

  .Info {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
