.Results {
  &.is-multi {
    margin-bottom: 3.5rem;
  }

  .accordion-item:first-of-type {
    border-top-right-radius: 0;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
  }

  .accordion-item .accordion-button {
    &::after {
      display: none;
    }

    &:not(:focus) {
      background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
      color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
    }
  }

  .badge {
    margin-left: auto;
  }

  ul {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      .flag {
        font-size: 1.1rem;
        line-height: 1.1rem;
        margin-right: .5rem;
      }

      .label {
        flex: 1;
        font-size: 0.95rem;

        .text {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ResultPopover {
  background-color: #e8e8e8;

  .Flag {
    font-size: 11.5rem;
    line-height: 11.5rem;
  }
}
