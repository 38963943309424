.StartedGame,
.EndedGame {
  @media (width <= 1214px) {
    .AppContentTop {
      left: auto;
      margin-left: auto;
      right: 348px;
    }
  }

  @media (width <= 916px) {
    .AppContentTop {
      left: 62px;
      right: 15px;
      width: auto;
    }
  }
}
