.AppContentTop {
  position: fixed;
  top: 15px;
  left: 50%;
  margin-left: -16rem;
  width: 32rem;
}

@media (width <= 625px) {
  .AppContentTop {
    left: 62px;
    right: 15px;
    margin-left: auto;
    width: auto;
  }
}

.AppContentTopLeftToolbar {
  position: fixed;
  left: 15px;
  top: 205px;
  width: 32px;

  * {
    max-width: 100%;
  }
}

.AppContentTopLeft {
  position: fixed;
  left: 3.5rem;
  top: 15px;
  max-width: 20rem;
  max-height: 419px;
  overflow-x: hidden;
  overflow-y: auto;
}

.AppContentRight {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: 2rem;
  width: 20rem;
  overflow-y: auto;
}

.AppContentBottom {
  position: fixed;
  bottom: 15px;
  left: 50%;
  margin-left: -11rem;
  width: 22rem;
  z-index: 1056;
}

.AppContentBottomLeft {
  position: fixed;
  bottom: 15px;
  left: 15px;
}

.AppContentBottomRight {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 1056;
}

.AppContentOffScreen {
  position: fixed;
  top: 0;
  left: 100%;
}

.modal-body.p-0 > .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}