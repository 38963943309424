.RoomChat {
  display: block;
  width: 20rem;
  max-width: calc(100vw - 2rem);
  transition: .1s width ease-in-out;

  &:not(.open) {
    @media (width <= 500px) {
      width: 10rem;
    }
  }

  .accordion-collapse.collapsing,
  .accordion-item:last-of-type {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .accordion-button:focus {
    background-color: var(--bs-accordion-active-bg) !important;
    color: var(--bs-accordion-active-color);
  }

  .accordion-button::after {
    transform: var(--bs-accordion-btn-icon-transform);
    margin-left: 0.75rem;
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(0);
  }

  .Messages {
    height: 25rem;
    overflow-x: hidden;
    overflow-y: scroll;

    .Username {
      position: relative;
      top: -2px;
    }

    .text-muted {
      font-size: .75rem;
    }
  }

  .ChatInput {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
  }

  .SendButton {
    border-radius: 0;
  }
}
