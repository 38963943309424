.ProfileHeader {
  @media (height <= 1000px) {
    max-height: 5rem;
    overflow-y: auto;
  }

  @media (height <= 700px) {
    max-height: 3rem;
  }
}
