.Profile {
  .locked {
    filter: blur(.33rem);
    user-select: none;

    img {
      filter: blur(.5rem);
    }
  }
}
