@import "~normalize.css/normalize.css";
@import "~@arcgis/core/assets/esri/themes/light/main.css";
@import "~bootstrap/scss/bootstrap";
@import "~flag-icons/css/flag-icons.min.css";

@import "./flags";

html, body, #root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: black;
  overflow: hidden;
}
