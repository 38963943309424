.ListScores {
  &.table {
    margin-bottom: 0;
  }

  tbody {
    tr:nth-child(1) {
      .medal {
        color: #c9b037;
      }
    }

    tr:nth-child(2) {
      .medal {
        color: #d7d7d7;
      }
    }

    tr:nth-child(3) {
      .medal {
        color: #ad8a56;
      }
    }
  }

  .number {
    font-family: var(--bs-font-monospace);
  }
}

.offcanvas {
  @media (width < 718px) {
    .ListScores {
      margin-bottom: 3.5rem;
    }
  }
}
