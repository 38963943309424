.Achievements {
  img {
    width: 250px;
    transition: .1s all ease-in-out;

    &:not(.unlocked) {
      opacity: 0.2;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}
