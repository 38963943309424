#globe {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;

  .esri-ui .esri-attribution {
    left: auto;
  }

  .esri-attribution__sources {
    display: none;
  }
}
